import React from 'react';
import './ProgressStepPersonel.scss';

const ProgressStepPersonel = ({ stepIndex }) => {
  const navigationList = [
    { name: 'Package Details' },
    { name: 'Price & Delivery \n Options' },
    { name: 'Setup   Account \n or Login' },
    { name: 'Address Details' },
    { name: 'Payment Details' },
  ];

  return (
    <div className="progress-step-personel">
      <div className="progress-step-personel__title">
        <h2>
          <span>Personel</span> quote
        </h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam.
        </p>
      </div>

      <ul className="list-unstyled multi-steps">
        {navigationList.map((nav, idx) => (
          <li key={nav.name} className={stepIndex === idx ? 'is-active' : ''}>
            {nav.name}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default ProgressStepPersonel;
