import React from 'react';
import Select from 'react-select';
import { Form, Button } from 'react-bootstrap';
import './PackageDetails.scss';

const optionsFrom = [
  { value: 'NY', label: 'New York' },
  { value: 'CN', label: 'Canada' },
  { value: 'AZ', label: 'Arizona' },
];

const optionsTo = [
  { value: 'LD', label: 'London' },
  { value: 'WT', label: 'Washington' },
  { value: 'NZ', label: 'New Zealand' },
];

const PackageDetails = ({
  navigation,
  packageDetailsForm,
  addNewLocationOrPackage,
  handleFormChange,
  handleLocationChange,
  handlePackageChange,
}) => {
  const { company, email, telephone, locations, packages } = packageDetailsForm;

  return (
    <div className="wrapper-packge-details">
      <div className="package-details">
        <Form>
          <div className="package-details__form-row mb-1">
            <div className="package-details__form-row__form-col">
              <Form.Group>
                <Form.Label>COMPANY:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name of Business…"
                  name="company"
                  value={company}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </div>
            <div className="package-details__form-row__form-col">
              <Form.Group>
                <Form.Label>EMAIL:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Business Email Address…"
                  name="email"
                  value={email}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </div>
            <div className="package-details__form-row__form-col">
              <Form.Group>
                <Form.Label>TELEPHONE NO.</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="+44 (0) …"
                  name="telephone"
                  value={telephone}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </div>
          </div>

          {locations.map(({ from, to, postcode }, idx) => (
            <div className="package-details__form-row mb-1" key={from + idx}>
              <div className="package-details__form-row__form-col">
                <Form.Group>
                  <Form.Label>FROM:</Form.Label>
                  <Select
                    options={optionsFrom}
                    name="from"
                    value={optionsFrom.filter((option) => option.value === from)}
                    onChange={({ value }, { name }) => handleLocationChange({ target: { name, value } }, idx)}
                  />
                </Form.Group>
              </div>
              <div className="package-details__form-row__form-col">
                <Form.Group>
                  <Form.Label>TO:</Form.Label>
                  <Select
                    options={optionsTo}
                    name="to"
                    value={optionsTo.filter((option) => option.value === to)}
                    onChange={({ value }, { name }) => handleLocationChange({ target: { name, value } }, idx)}
                  />
                </Form.Group>
              </div>
              <div className="package-details__form-row__form-col">
                <Form.Group>
                  <Form.Label>YOUR POSTCODE (UK):</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="UK ONLY…"
                    name="postcode"
                    value={postcode}
                    onChange={(e) => handleLocationChange(e, idx)}
                  />
                </Form.Group>
              </div>
            </div>
          ))}

          <div className="add-button mb-3" onClick={() => addNewLocationOrPackage(packageDetailsForm, 'locations')}>
            <span> +</span>
            <Button variant="light">ADD ADDITIONAL LOCATION</Button>
          </div>

          {packages.map(({ quantity, weight, length, width, height }, idx) => (
            <div className="package-details__form-row_child mb-1" key={idx + 10}>
              <div className="package-details__form-row_child__form-col">
                <Form.Group>
                  <Form.Label>QUANTITY:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="1"
                    name="quantity"
                    value={quantity}
                    onChange={(e) => handlePackageChange(e, idx)}
                  />
                </Form.Group>
              </div>
              <div className="package-details__form-row_child__form-col">
                <Form.Group>
                  <Form.Label>WEIGHT</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="1"
                    name="weight"
                    value={weight}
                    onChange={(e) => handlePackageChange(e, idx)}
                  />
                </Form.Group>
              </div>
              <div className="package-details__form-row_child__form-col">
                <Form.Group>
                  <Form.Label>LENGTH:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="0 cm"
                    name="length"
                    value={length}
                    onChange={(e) => handlePackageChange(e, idx)}
                  />
                </Form.Group>
              </div>
              <div className="package-details__form-row_child__form-col">
                <Form.Group>
                  <Form.Label>WIDTH:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="0 cm"
                    name="width"
                    value={width}
                    onChange={(e) => handlePackageChange(e, idx)}
                  />
                </Form.Group>
              </div>
              <div className="package-details__form-row_child__form-col">
                <Form.Group>
                  <Form.Label>HEIGHT:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="0 cm"
                    name="height"
                    value={height}
                    onChange={(e) => handlePackageChange(e, idx)}
                  />
                </Form.Group>
              </div>
            </div>
          ))}

          <div className="add-button mb-3" onClick={() => addNewLocationOrPackage(packageDetailsForm, 'packages')}>
            <span> +</span>
            <Button variant="light">ADD ADDITIONAL PACKAGE</Button>
          </div>

          <div className="add-check mb-3">
            <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1" />
            <label htmlFor="styled-checkbox-1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </label>
          </div>
          <div className="button-quote text-center">
            <Button variant="dark" onClick={() => navigation.updateAndNext({ packageDetailsForm })}>
              CONTINUE QUOTE
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default PackageDetails;
