import React from 'react';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import './Header.scss';

const Header = () => {
  return (
    <header>
      <div className="top-header">
        <Container>
          <div className="top-header__right-text">
            <img src="/dhl-business-small.png" alt="" />
            <p>Authorised Service partner</p>
          </div>
        </Container>
      </div>
      <div className="primary-header">
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand href="#home">
              {' '}
              <img src="/navbar-logo.png" alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="#">NAVIGATION TAB 1</Nav.Link>
                <Nav.Link href="#">NAVIGATION TAB 2</Nav.Link>
                <Nav.Link href="#">NAVIGATION TAB 3</Nav.Link>
                <Nav.Link href="#">NAVIGATION TAB 4</Nav.Link>
              </Nav>
              <Button variant="warning">LOG IN</Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
};
export default Header;
