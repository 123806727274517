import React from 'react';
import { Card } from 'react-bootstrap';
import './CardGrid.scss';

const CardGrid = () => {
  return (
    <div className="content-grid-homepage">
      <div className="content-grid-homepage__wrapper-card">
        <Card>
          <Card.Img variant="top" src="/Ellipse1@2x.png" />
          <Card.Body>
            <Card.Title>Lorem ipsum dolor sit amet,consectetu</Card.Title>
            <Card.Text className="d-none d-md-block">
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Img variant="top" src="/Ellipse1@2x.png" />
          <Card.Body>
            <Card.Title>Lorem ipsum dolor sit amet,consectetu</Card.Title>
            <Card.Text className="d-none d-md-block">
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Img variant="top" src="/Ellipse1@2x.png" />
          <Card.Body>
            <Card.Title>Lorem ipsum dolor sit amet,consectetu</Card.Title>
            <Card.Text className="d-none d-md-block">
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Img variant="top" src="/Ellipse1@2x.png" />
          <Card.Body>
            <Card.Title>Lorem ipsum dolor sit amet,consectetu</Card.Title>
            <Card.Text className="d-none d-md-block">
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
export default CardGrid;
