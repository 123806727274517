import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from 'pages/HomePage/HomePage';
import PersonelQuotePage from 'pages/PersonelQuotePage/PersonelQuotePage';
import BussinessQuotePage from 'pages/BussinessQuotePage/BussinessQuotePage';

import './App.scss';

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/personel-quote" component={PersonelQuotePage} />
        <Route path="/bussiness-quote" component={BussinessQuotePage} />
      </Switch>
    </div>
  );
};

export default App;
