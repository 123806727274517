import React from 'react';
import Header from 'components/Header/Header';
import PersonelQuote from 'components/PersonelQuote/PersonelQuote';

const PersonelQuotePage = () => {
  // const dataProgress = {
  //   Title: 'personel',
  //   SubTitle:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.y',
  // };

  return (
    <>
      <Header />
      <PersonelQuote />
    </>
  );
};

export default PersonelQuotePage;
