import React from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import './PaymentOption.scss';

const PaymentOption = ({ navigation, formData, selectedDeliveryOption, paymentDetails, handleChange, handleNext }) => {
  return (
    <div className="payment-option">
      <div className="payment-option__left-content">
        <div className="delivery-details">
          <label className="top-lebel">
            COLLECTION & DELIVERY DETAILS: <span>(Please check details are correct before purchase)</span>
          </label>
          <div className="delivery-details__row">
            <div className="delivery-details__row__col-left">
              <h6>Collection Details:</h6>
              <p>
                NAME / BUSINESS
                <br /> ADDRESS, COUNTRY
              </p>
              <p>DATE OF COLLECTION</p>
            </div>
            <div className="delivery-details__row__col-right">
              <h6>Delivery Details:</h6>
              <p>
                NAME / BUSINESS <br />
                ADDRESS, COUNTRY
              </p>
              <p>DATE OF DELIVERY</p>
            </div>
          </div>
        </div>
        <div className="secure-payment">
          <label className="top-lebel">
            SECURE PAYMENT <span>(Please enter your credit or debit details directly within the card below)</span>
          </label>

          <Form>
            <div className="secure-payment-box-wrapper">
              <div className="secure-payment-box-wrapper__payment-box">
                <div className="form-group-wrapper">
                  <Form.Group>
                    <Form.Label>16 Digit Card Number *</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="......"
                      value={paymentDetails.cardNumber}
                      name="cardNumber"
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Expiry Date (MM/YY)*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Business Email Address…"
                      value={paymentDetails.expireDate}
                      name="expireDate"
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Card Security Code *</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="+44 (0) …"
                      value={paymentDetails.securityCode}
                      name="securityCode"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="add-check mb-3">
                  <input className="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="value2" />
                  <label htmlFor="styled-checkbox-3">Securely Save to Account</label>
                </div>
              </div>

              <div className="group-buttons text-center mt-3">
                <Button variant="dark" onClick={navigation.previous}>
                  BACK TO ACCOUNT SETUP
                </Button>
                <Button variant="dark" onClick={handleNext}>
                  CONTINUE TO PAYMENT
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div className="payment-option__next-day-delivery">
        <Card className={`text-center ${selectedDeliveryOption.package}`}>
          <div className="highlights-tooltip text-center">
            <div className="highlights-tooltip__inner">
              {selectedDeliveryOption.minDays} - {selectedDeliveryOption.maxDays} delivery
            </div>
          </div>
          <div className="partial-bg"></div>
          <Card.Img variant="top" src="/DHL-Logo.png" />
          <Card.Body>
            <Card.Title>DROP OFF / PICK UP INFO TEXT GOES HERE</Card.Title>

            <Card.Text className="text-left card-text-color">
              £{selectedDeliveryOption.price.withoutVat} exc VAT
            </Card.Text>
            <Card.Text className="text-left">£{selectedDeliveryOption.price.withVat} inc VAT</Card.Text>
          </Card.Body>
          <div className="card-footer"></div>
        </Card>
      </div>
    </div>
  );
};
export default PaymentOption;
