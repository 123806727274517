import React from 'react';
import Header from 'components/Header/Header';
import BusinessQuote from 'components/BusinessQuote/BusinessQuote';

const BussinessQuotePage = () => {
  return (
    <>
      <Header />
      <BusinessQuote />
    </>
  );
};

export default BussinessQuotePage;
