import React from 'react';

import { Link } from 'react-router-dom';
import { Accordion, Card, Button } from 'react-bootstrap';
import './ChooseWindow.scss';

const ChooseWindow = () => {
  return (
    <div className="choose-window">
      <div className="choose-window__block-wrapper">
        <div className="choose-window__block-wrapper__left-content">
          <div className="choose-window__block-wrapper__left-content__wrapper-text">
            <h1>personel</h1>
            <p>Lorem ipsum dolor sit amet, consectetu</p>
            {/* <Button color="secondary">SEND PARCEL</Button> */}
            <Link to="/personel-quote">SEND PARCEL</Link>
          </div>
        </div>
        <div className="choose-window__block-wrapper__right-content">
          <div className="choose-window__block-wrapper__right-content__wrapper-text-right">
            <h1>business</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adi</p>
            {/* <Button color="secondary">CREATE ACCOUNT</Button> */}
            <Link to="/bussiness-quote">CREATE ACCOUNT</Link>
          </div>
        </div>
      </div>
      <div className="choose-on-mobile">
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                Click me!
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>MOBILE TITLE HEADER</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle variant="link" eventKey="1">
                <div>
                  <img src="/arrow-small-white.png" alt="" />
                </div>
                <div>
                  <h3>business</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adi</p>
                </div>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <p>Lorem ipsum dolor sit amet, consectetu adi</p>
                <Link to="/bussiness-quote" className="ctc-button">
                  CTA BUTTON
                </Link>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle variant="link" eventKey="2">
                <div>
                  <img src="/arrow-small.png" alt="" />
                </div>
                <div>
                  <h3>personel</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adi</p>
                </div>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <p>Lorem ipsum dolor sit amet, consectetu adi</p>
                <Link to="/personel-quote" className="ctc-button">
                  CTA BUTTON
                </Link>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
};
export default ChooseWindow;
