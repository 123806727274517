import React from 'react';
import Header from 'components/Header/Header';
import ChooseWindow from 'components/ChooseWindow/ChooseWindow';
import CardGrid from 'components/CardGrid/CardGrid';
import Partners from 'components/Partners/Partners';

const HomePage = () => {
  return (
    <>
      <Header />
      <ChooseWindow />
      <CardGrid />
      <Partners />
    </>
  );
};

export default HomePage;
