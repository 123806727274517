import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import ProgressStep from 'components/ProgressStep/ProgressStep';
import PackageDetails from 'components/PackageDetails/PackageDetails';
import DeliveryOptions from 'components/DeliveryOptions/DeliveryOptions';
import SetupBusiness from 'components/SetupBusiness/SetupBusiness';
import AddressDetails from 'components/AddressDetails/AddressDetails';
import PaymentOption from 'components/PaymentOption/PaymentOption';

import './BusinessQuote.scss';

const BusinessQuote = () => {
  //PACKAGE DETAILS DATA AND HANDLER

  const packageDetailsDefaultState = {
    company: '',
    email: '',
    telephone: '',
    locations: [
      {
        from: '',
        to: '',
        postcode: '',
      },
    ],
    packages: [
      {
        quantity: '',
        weight: '',
        length: '',
        width: '',
        height: '',
      },
    ],
  };

  const [packageDetailsForm, setPackageDetailsForm] = useState(packageDetailsDefaultState);

  const addNewLocationOrPackage = (formState, propertyName) => {
    setPackageDetailsForm({
      ...formState,
      [propertyName]: [...formState[propertyName], ...packageDetailsDefaultState[propertyName]],
    });
    return;
  };

  const handleFormChange = (e) => {
    setPackageDetailsForm({
      ...packageDetailsForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleLocationChange = (e, idx) => {
    setPackageDetailsForm({
      ...packageDetailsForm,
      locations: packageDetailsForm.locations.map((location, index) =>
        index === idx ? { ...location, [e.target.name]: e.target.value } : location
      ),
    });
  };

  const handlePackageChange = (e, idx) => {
    setPackageDetailsForm({
      ...packageDetailsForm,
      packages: packageDetailsForm.packages.map((eachPackage, index) =>
        index === idx ? { ...eachPackage, [e.target.name]: e.target.value } : eachPackage
      ),
    });
  };

  //END PACKAGE DETAILS DATA AND HANDLER

  // DELIVERY OPTION DATA AND HANDLER

  const deliveryOptions = [
    {
      package: 'gold',
      minDays: '1',
      maxDays: '1',
      price: {
        withVat: '4.00',
        withoutVat: '1.00',
      },
    },
    {
      package: 'silver',
      minDays: '1',
      maxDays: '2',
      price: {
        withVat: '4.00',
        withoutVat: '1.00',
      },
    },
    {
      package: 'bronze',
      minDays: '3',
      maxDays: '5',
      price: {
        withVat: '4.00',
        withoutVat: '1.00',
      },
    },
  ];

  const handleNextStep = () => {
    if (selectedDeliveryOption === '') {
      return alert('Please selection atleast one option to proceed');
    }
    navigation.next();
  };

  // END DELIVERY OPTION DATA AND HANDLER

  // SETUP BUSSINESS DATA AND HANDLER
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
  });

  const [registrationDetails, setRegistrationDetails] = useState({
    businessName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    companyNumber: '',
    vatNumber: '',
    postcode: '',
  });

  const handleLoginChange = (e) => {
    setLoginDetails({
      ...loginDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleRegistrationChange = (e) => {
    setRegistrationDetails({
      ...registrationDetails,
      [e.target.name]: e.target.value,
    });
  };
  // END SETUP BUSSINESS DATA AND HANDLER

  // ADDRESS DETAILS DATA AND HANDLER

  const [shippingAddress, setShippingAddress] = useState({
    businessName: '',
    firstName: '',
    lastName: '',
    postcode: '',
  });

  const [collectionDetails, setCollectionDetails] = useState({
    businessName: '',
    fisrtName: '',
    lastName: '',
    email: '',
    phone: '',
    postcode: '',
  });

  const handleShippingAddressChange = (e) => {
    setShippingAddress({
      ...shippingAddress,
      [e.target.name]: e.target.value,
    });
  };

  const handleCollectionDetailsChange = (e) => {
    setCollectionDetails({
      ...collectionDetails,
      [e.target.name]: e.target.value,
    });
  };

  // END ADDRESS DETAILS DATA AND HANDLER

  // PAYMENT OPTION DATA AND HANDLER

  const [paymentDetails, setPaymentDetails] = useState({
    cardNumber: '',
    expireDate: '',
    securityCode: '',
  });

  const handleChange = (e) => {
    setPaymentDetails({
      ...paymentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleNext = () => {
    navigation.updateAndNext({ paymentDetails });
    alert('See console for all the input data');
    console.log(formData);
  };

  // END PAYMENT OPTION DATA AND HANDLER

  const [formData, setFormData] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState('');

  const goToNextStep = () => {
    setCurrentStep((currentStep + 1) % formsToShow.length);
  };

  const goToPrevStep = () => {
    setCurrentStep((currentStep - 1) % formsToShow.length);
  };

  const navigation = {
    next: goToNextStep,
    previous: goToPrevStep,
    updateAndNext: (newFormData) => {
      updateFormData(newFormData);
      goToNextStep();
    },
  };

  const updateFormData = (newFormData) => {
    setFormData({
      ...formData,
      ...newFormData,
    });
  };

  // const dataProgressBussiness = {
  //   Title: 'bussiness',
  //   SubTitle:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.y',
  // };

  const formsToShow = [
    <PackageDetails
      navigation={navigation}
      packageDetailsForm={packageDetailsForm}
      addNewLocationOrPackage={addNewLocationOrPackage}
      handleFormChange={handleFormChange}
      handleLocationChange={handleLocationChange}
      handlePackageChange={handlePackageChange}
    />,
    <DeliveryOptions
      navigation={navigation}
      selectedDeliveryOption={selectedDeliveryOption}
      setSelectedDeliveryOption={setSelectedDeliveryOption}
      deliveryOptions={deliveryOptions}
      handleNextStep={handleNextStep}
    />,
    <SetupBusiness
      navigation={navigation}
      selectedDeliveryOption={selectedDeliveryOption}
      loginDetails={loginDetails}
      registrationDetails={registrationDetails}
      handleLoginChange={handleLoginChange}
      handleRegistrationChange={handleRegistrationChange}
    />,
    <AddressDetails
      navigation={navigation}
      selectedDeliveryOption={selectedDeliveryOption}
      shippingAddress={shippingAddress}
      collectionDetails={collectionDetails}
      handleShippingAddressChange={handleShippingAddressChange}
      handleCollectionDetailsChange={handleCollectionDetailsChange}
    />,
    <PaymentOption
      navigation={navigation}
      selectedDeliveryOption={selectedDeliveryOption}
      formData={formData}
      paymentDetails={paymentDetails}
      handleChange={handleChange}
      handleNext={handleNext}
    />,
  ];

  return (
    <div className="business-quote">
      <Container>
        <div className="business-quote-outer">
          <ProgressStep stepIndex={currentStep} />
          {formsToShow[currentStep]}
        </div>
      </Container>
    </div>
  );
};
export default BusinessQuote;
