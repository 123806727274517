import React from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import './AddressDetails.scss';

const AddressDetails = ({
  navigation,
  selectedDeliveryOption,
  shippingAddress,
  collectionDetails,
  handleShippingAddressChange,
  handleCollectionDetailsChange,
}) => {
  return (
    <div className="address-details">
      <div className="address-details__left-content">
        <div className="shipping-to">
          <Form>
            <Form.Label>SHIPPING TO:</Form.Label>
            <div className="shipping-to__row1">
              <div className="shipping-to__row1__cols">
                <Form.Group>
                  <Form.Control
                    type="businessName"
                    placeholder="BUSINESS NAME (OPTIONAL)"
                    value={shippingAddress.businessName}
                    name="businessName"
                    onChange={handleShippingAddressChange}
                  />
                </Form.Group>
              </div>
              <div className="shipping-to__row1__cols">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="FIRST NAME…"
                    value={shippingAddress.firstName}
                    name="firstName"
                    onChange={handleShippingAddressChange}
                  />
                </Form.Group>
              </div>
              <div className="shipping-to__row1__cols">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="LAST NAME…"
                    value={shippingAddress.lastName}
                    name="lastName"
                    onChange={handleShippingAddressChange}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="shipping-to__row2">
              <div className="shipping-to__row2__cols">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Enter postcode to find delivery address"
                    value={shippingAddress.postcode}
                    name="postcode"
                    onChange={handleShippingAddressChange}
                  />
                </Form.Group>
              </div>

              <div className="find-address">
                <Button>FIND ADDRESS</Button>
              </div>
            </div>
          </Form>
        </div>

        <div className="collection-details">
          <Form>
            <Form.Label>
              COLLECTION DETAILS <span>(If different from Business Address)</span>
            </Form.Label>
            <div className="collection-details__row1">
              <div className="collection-details__row1__cols">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="BUSINESS NAME…"
                    value={collectionDetails.businessName}
                    onChange={handleCollectionDetailsChange}
                    name="businessName"
                  />
                </Form.Group>
              </div>
              <div className="collection-details__row1__cols">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="FIRST NAME…"
                    value={collectionDetails.firstName}
                    onChange={handleCollectionDetailsChange}
                    name="firstName"
                  />
                </Form.Group>
              </div>
              <div className="collection-details__row1__cols">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="LAST NAME…"
                    value={collectionDetails.lastName}
                    onChange={handleCollectionDetailsChange}
                    name="lastName"
                  />
                </Form.Group>
              </div>
            </div>

            <div className="collection-details__row2">
              <div className="collection-details__row2__cols">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="EMAIL ADDRESS…."
                    value={collectionDetails.email}
                    onChange={handleCollectionDetailsChange}
                    name="email"
                  />
                </Form.Group>
              </div>
              <div className="collection-details__row2__cols">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="PHONE NO. +44 (0)"
                    value={collectionDetails.phone}
                    onChange={handleCollectionDetailsChange}
                    name="phone"
                  />
                </Form.Group>
              </div>
            </div>

            <div className="collection-details__row3">
              <div className="collection-details__row3__cols">
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="ENTER POSTCODE TO FIND BUSINESS ADDRESS…"
                    value={collectionDetails.postcode}
                    name="postcode"
                    onChange={handleShippingAddressChange}
                  />
                </Form.Group>
              </div>

              <div className="find-address">
                <Button>FIND ADDRESS</Button>
              </div>
            </div>

            <div className="add-check mb-3">
              <input className="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2" />
              <label htmlFor="styled-checkbox-2">
                I have read and agree to the terms and conditions set out by Impact Express.
              </label>
            </div>
            <div className="group-buttons text-center">
              <Button variant="dark" onClick={navigation.previous}>
                BACK TO ACCOUNT SETUP
              </Button>
              <Button variant="dark" onClick={() => navigation.updateAndNext({ shippingAddress, collectionDetails })}>
                CONTINUE TO PAYMENT
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div className="address-details__next-day-delivery">
        <Card className={`text-center ${selectedDeliveryOption.package}`}>
          <div className="highlights-tooltip text-center">
            <div className="highlights-tooltip__inner">
              {selectedDeliveryOption.minDays} - {selectedDeliveryOption.maxDays} delivery
            </div>
          </div>
          <div className="partial-bg"></div>
          <Card.Img variant="top" src="/DHL-Logo.png" />
          <Card.Body>
            <Card.Title>DROP OFF / PICK UP INFO TEXT GOES HERE</Card.Title>

            <Card.Text className="text-left card-text-color">
              £{selectedDeliveryOption.price.withoutVat} exc VAT
            </Card.Text>
            <Card.Text className="text-left">£{selectedDeliveryOption.price.withVat} inc VAT</Card.Text>
          </Card.Body>
          <div className="card-footer"></div>
        </Card>
      </div>
    </div>
  );
};
export default AddressDetails;
