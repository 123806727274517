import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Partners.scss';

const Partners = () => {
  return (
    <div className="our-partners">
      <Container fluid={true}>
        <div className="our-partners-inner">
          <div className="our-partners-inner__left-logo">
            <img src="/dhl-business-live-png-logo-3@2x.png" alt="" />
            <img src="/DHL Authorised Service Partner@2x.png" alt="" />
          </div>

          <div className="our-partners-inner__right-content">
            <h3>Our Partnership</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
              electronic typesetting
            </p>
            <Link to="/" className="learn-more">
              LEARN MORE
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Partners;
