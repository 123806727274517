import React from 'react';
import { Button, Card } from 'react-bootstrap';

import './DeliveryOptions.scss';

const DeliveryOptions = ({
  navigation,
  setSelectedDeliveryOption,
  selectedDeliveryOption,
  deliveryOptions,
  handleNextStep,
}) => {
  return (
    <div className="price-delivery">
      <div className="price-delivery__card">
        {deliveryOptions.map((deliveryOption) => (
          <Card className="text-center" key={deliveryOption.package}>
            <div className="highlights-tooltip text-center">
              <div className="highlights-tooltip__inner">
                {deliveryOption.minDays} - {deliveryOption.maxDays} delivery
              </div>
            </div>
            <div className="partial-bg"></div>
            <div className="wrapper-img-card">
              <Card.Img variant="top" src="/DHL-Logo.png" />
            </div>

            <Card.Body>
              <Card.Title>DROP OFF / PICK UP INFO TEXT GOES HERE</Card.Title>

              <Card.Text className="text-left card-text-color">£{deliveryOption.price.withoutVat} exc VAT</Card.Text>
              <Card.Text className="text-left">£{deliveryOption.price.withVat} inc VAT</Card.Text>

              <Button variant="primary" onClick={() => setSelectedDeliveryOption(deliveryOption)}>
                {selectedDeliveryOption.package === deliveryOption.package ? 'SELECTED' : 'SELECT'}
              </Button>
            </Card.Body>
            <div className="card-footer"></div>
          </Card>
        ))}
      </div>

      <div className="group-buttons">
        <Button variant="dark" onClick={navigation.previous}>
          BACK TO PACKAGE DETAILS
        </Button>
        <Button variant="dark" onClick={handleNextStep}>
          CONTINUE TO ACCOUNT
        </Button>
      </div>
    </div>
  );
};
export default DeliveryOptions;
